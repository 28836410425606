import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Process = () => (
  <Layout>
    <SEO title="Modern Web Application Development Process" description="Custom web, mobile and e-commerce development that empowers business growth and helps businesses succeed in their digital transformation path."/>
    <div style={{left: '-80px', overflow: 'hidden'}} className={`absolute hidden md:block`}>
        <svg width="195" height="87" viewBox="0 0 195 87" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M195 84.1704C195 82.928 194.08 81.8776 192.849 81.7138L-5.1951 55.3731C-6.68047 55.1755 -8 56.3312 -8 57.8296V57.8296C-8 59.072 -7.08008 60.1224 -5.84856 60.2862L192.195 86.6269C193.68 86.8245 195 85.6688 195 84.1704V84.1704Z" fill="#F16571"/>
            <path d="M135 29.1704C135 27.928 134.08 26.8776 132.849 26.7138L-65.1951 0.373066C-66.6805 0.175504 -68 1.33116 -68 2.82961V2.82961C-68 4.07198 -67.0801 5.12235 -65.8486 5.28615L132.195 31.6269C133.68 31.8245 135 30.6688 135 29.1704V29.1704Z" fill="#3E2B7D"/>
        </svg>

    </div>
    <div className={`absolute right-0 hidden md:block`}>
      <svg width="167" height="84" viewBox="0 0 167 84" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 81.1704C0 79.928 0.919917 78.8776 2.15144 78.7138L200.195 52.3731C201.68 52.1755 203 53.3312 203 54.8296C203 56.072 202.08 57.1224 200.849 57.2862L2.8049 83.6269C1.31953 83.8245 0 82.6688 0 81.1704Z" fill="#F3F2F6"/>
        <path d="M60 29.1704C60 27.928 60.9199 26.8776 62.1514 26.7138L260.195 0.373066C261.68 0.175504 263 1.33116 263 2.82961C263 4.07198 262.08 5.12235 260.849 5.28615L62.8049 31.6269C61.3195 31.8245 60 30.6688 60 29.1704Z" fill="#F3F2F6"/>
      </svg>
    </div>
    <div style={{maxWidth: '531px'}} className={'mx-auto mb-12 mt-8 lg:mb-12'}>
        <h1 className={`text-3xl text-center md:text-4xl font-bold text-purple-800 leading-10 mb-2 md:mb-4`}>We engage in a result focused and forward moving process to get your web project delivered</h1>
    </div>
    <div className={`mx-auto max-w-md flex flex-col items-center mb-12`}>
        <p className={`text-sm md:text-base text-center font-bold text-purple-800`}>Our first conversation</p>
        <div className={`w-16 border-b-4 border-orange-500 my-4`}></div>
        <p className={`text-center text-purple-800 mb-2`}>It all starts somewhere. On the first conversation we are just looking to understand the basics of what you are trying to achieve and see if we can help. We love challenges big and small so reach out and let’s talk.</p>
        <Link to={`/web-development-quote`}><p className={`font-bold text-orange-500 text-center`}>Get in touch</p></Link>
    </div>
    <div className={`relative h-full mb-24`}>
      <div style={{minHeight: '750px', transform: `skewY(5deg)`}} className={`w-full absolute bg-purple-100`}></div>
      <div style={{minHeight: '720px', transform: `skewY(-5deg)`}} className={`w-full relative bg-purple-800`}>
        <div className={`absolute`} style={{top: '36px', right: '-80px', overflow: 'hidden', transform: `skewY(3deg)`}}>
          <svg width="195" height="84" viewBox="0 0 195 84" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 81.1704C0 79.928 0.919917 78.8776 2.15144 78.7138L200.195 52.3731C201.68 52.1755 203 53.3312 203 54.8296C203 56.072 202.08 57.1224 200.849 57.2862L2.8049 83.6269C1.31953 83.8245 0 82.6688 0 81.1704Z" fill="#F16571"/>
            <path d="M60 29.1704C60 27.928 60.9199 26.8776 62.1514 26.7138L260.195 0.373066C261.68 0.175504 263 1.33116 263 2.82961C263 4.07198 262.08 5.12235 260.849 5.28615L62.8049 31.6269C61.3195 31.8245 60 30.6688 60 29.1704Z" fill="white"/>
          </svg>
        </div>
        <div style={{transform: `skewY(5deg)`}} className={`  pt-32 pb-24`}>
            <div className={`flex flex-col lg:flex-row items-center lg:items-start justify-around mb-20`}>
                <div className={`flex flex-col max-w-xs items-center mb-16 lg:mb-0`}>
                    <div className={`mb-4`}>
                    <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16 4.87957L0 0V0.921644L16 7L16 4.87957Z" fill="white"/>
                        <path d="M0 10.0506L16 5V13.9494L0 19V10.0506Z" fill="white"/>
                    </svg>
                    </div>
                    <h2 className={`text-lg text-white font-bold text-center tracking-normal`}>Discovery</h2>
                    <div className={`w-16 border-b-4 border-orange-500 my-4`}></div>
                    <p className={`text-white text-center`}>A crucial step in all projects, discovery let’s get an understanding of your objectives and explore paths to delivery. It allows you and us to move to the next steps with confidence.</p>
                </div>

                <div className={`flex flex-col max-w-xs items-center mb-16 lg:mb-0`}>
                    <div className={`mb-4`}>
                    <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16 4.87957L0 0V0.921644L16 7L16 4.87957Z" fill="white"/>
                        <path d="M0 10.0506L16 5V13.9494L0 19V10.0506Z" fill="white"/>
                    </svg>
                    </div>
                    <h2 className={`text-lg text-white font-bold text-center tracking-normal`}>Design</h2>
                    <div className={`w-16 border-b-4 border-orange-500 my-4`}></div>
                    <p className={`text-white text-center`}>We draft the initial wireframes, implement branding, look at the user journey and their actions and draft acceptance criteria. We are now ready to move to implementation.</p>
                </div>

                <div className={`flex flex-col max-w-xs items-center mb-16 lg:mb-0`}>
                    <div className={`mb-4`}>
                    <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16 4.87957L0 0V0.921644L16 7L16 4.87957Z" fill="white"/>
                        <path d="M0 10.0506L16 5V13.9494L0 19V10.0506Z" fill="white"/>
                    </svg>
                    </div>
                    <h2 className={`text-lg text-white font-bold text-center tracking-normal`}>Implementation</h2>
                    <div className={`w-16 border-b-4 border-orange-500 my-4`}></div>
                    <p className={`text-white text-center`}>The most technical intensive process. We develop the back end and the front end of the application delivering highly performant, secure and scalable solutions.</p>
                </div>          
            </div>
            <div className={`flex flex-col lg:flex-row items-center lg:items-start justify-around`}>
                <div className={`flex flex-col max-w-xs items-center mb-16 lg:mb-0`}>
                    <div className={`mb-4`}>
                    <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16 4.87957L0 0V0.921644L16 7L16 4.87957Z" fill="white"/>
                        <path d="M0 10.0506L16 5V13.9494L0 19V10.0506Z" fill="white"/>
                    </svg>
                    </div>
                    <h2 className={`text-lg text-white font-bold text-center tracking-normal`}>Review</h2>
                    <div className={`w-16 border-b-4 border-orange-500 my-4`}></div>
                    <p className={`text-white text-center`}>We conduct our own internal review and run security tests, evaluate how user data is used and safeguarded, evaluate performance. Depending on the application adn scope we may request an indipendent third party security review.</p>
                </div>

                <div className={`flex flex-col max-w-xs items-center mb-16 lg:mb-0`}>
                    <div className={`mb-4`}>
                    <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16 4.87957L0 0V0.921644L16 7L16 4.87957Z" fill="white"/>
                        <path d="M0 10.0506L16 5V13.9494L0 19V10.0506Z" fill="white"/>
                    </svg>
                    </div>
                    <h2 className={`text-lg text-white font-bold text-center tracking-normal`}>Delivery</h2>
                    <div className={`w-16 border-b-4 border-orange-500 my-4`}></div>
                    <p className={`text-white text-center`}>Your website or application is now live and users are now involved. We keep a close eye on any issues and make sure to provide fast resolution to any issue that may come up.</p>
                </div>   
                <div className={`flex flex-col max-w-xs items-center mb-16 lg:mb-0`}>
                    <div className={`mb-4`}>
                    <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16 4.87957L0 0V0.921644L16 7L16 4.87957Z" fill="white"/>
                        <path d="M0 10.0506L16 5V13.9494L0 19V10.0506Z" fill="white"/>
                    </svg>
                    </div>
                    <h2 className={`text-lg text-white font-bold text-center tracking-normal`}>Support</h2>
                    <div className={`w-16 border-b-4 border-orange-500 my-4`}></div>
                    <p className={`text-white text-center`}>We provide ongoing support for the project. If we took care of deployment we make sure that the website/application remains secure. We monitor for any performance issues as you scale. </p>
                </div>       
            </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default Process
